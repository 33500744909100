<template>
  <div
    class="program"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 列表 -->
    <div class="programlist">
      <!-- 上 -->
      <div style="display: flex; margin-bottom: 5px">
        <el-form :model="SerachInput" label-width="90px">
          <div style="display: flex; flex-wrap: wrap; align-items: center">
            <el-form-item label="出库时间" class="timeRight">
              <el-date-picker
                v-model="overallForm.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="出库状态">
              <el-select
                v-model="SerachInput.status"
                placeholder="请选择"
                clearable
                style="width: 150px"
              >
                <el-option
                  v-for="item in outOpStatus"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="销售分组">
              <el-select
                v-model="SerachInput.groupId"
                placeholder="请选择"
                clearable
                style="width: 150px"
              >
                <el-option
                  v-for="item in opStatus"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="出库方式">
              <el-select
                v-model="SerachInput.outWay"
                placeholder="请选择"
                clearable
                style="width: 150px"
              >
                <el-option
                  v-for="item in outOpWay"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="出库类型">
              <el-select
                v-model="SerachInput.type"
                placeholder="请选择"
                clearable
                style="width: 150px"
              >
                <el-option
                  v-for="item in outOpType"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" class="timeRight">
              <el-select
                v-model="SerachInput.last"
                placeholder="请选择"
                clearable
                style="width: 110px"
                @change="changeSel"
              >
                <el-option
                  v-for="item in optionsLast"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
              <el-input
                v-model="SerachInput.value"
                placeholder="请输入"
                style="width: 180px"
                clearable
                @input="$forceUpdate()"
                @change="$forceUpdate()"
                v-if="isShow"
              ></el-input>
              <el-select
                v-model="SerachInput.createId"
                placeholder="请选择"
                clearable
                style="width: 130px"
                @input="$forceUpdate()"
                @change="$forceUpdate()"
                v-else
              >
                <el-option
                  v-for="item in optionsCreate"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <img
                src="../../assets/images/query.png"
                alt=""
                @click="imaClick"
                style="width: 49px; height: 29px; margin-left: 10px"
              />
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- table -->
      <div class="tables">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            prop="hisNo"
            label="编号"
            width="150px"
          ></el-table-column>
          <el-table-column
            prop="userName"
            label="操作员工"
            width="150px"
          ></el-table-column>
          <el-table-column
            prop="count"
            label="设备数量"
            width="160px"
          ></el-table-column>
          <el-table-column
            prop="groupName"
            label="销售分组"
            width="180px"
          ></el-table-column>
          <el-table-column
            prop="dateTime"
            label="出库时间"
            width="160px"
          ></el-table-column>
          <el-table-column
            prop="typeCn"
            label="类型"
            width="160px"
          ></el-table-column>
          <el-table-column
            prop="statusCn"
            label="出库状态"
            width="160px"
          ></el-table-column>
          <el-table-column
            prop="outWayCn"
            label="出库方式"
            width="180px"
          ></el-table-column>
          <el-table-column label="操作" width="180px">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="configuration(scope.$index, scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <div
            style="
              margin-left: 25px;
              margin-bottom: 15px;
              font-size: 16px;
              color: #606266;
            "
          >
            共计: {{ totalCount }}条数据
          </div>
        </div>
        <div class="bottom-right">
          <div class="bottom-fen">
            <!-- 分页 -->
            <div class="pages">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="pageCount"
                layout="total, prev, pager, next"
                :total="totalCount"
              >
              </el-pagination>
            </div>
          </div>
          <!-- <div class="buttons">
            <el-button @click="exportList">数据导出</el-button>
          </div> -->
        </div>
      </div>
    </div>
    <div class="outTan">
      <el-dialog
        title="出库记录"
        :visible.sync="dialogVisibleS"
        width="50%"
        :before-close="handleCloseS"
        :close-on-click-modal="false"
      >
        <div class="outTable">
          <el-table :data="tableDataOne" style="width: 100%">
            <el-table-column
              prop="userName"
              label="操作员工"
              width="130px"
            ></el-table-column>
            <el-table-column
              prop="count"
              label="设备数量"
              width="100px"
            ></el-table-column>
            <el-table-column
              prop="dateTime"
              label="出库时间"
              width="160px"
            ></el-table-column>
            <el-table-column
              prop="typeCn"
              label="类型"
              width="140px"
            ></el-table-column>
            <el-table-column
              prop="statusCn"
              label="出库状态"
              width="160px"
            ></el-table-column>
            <el-table-column
              prop="groupName"
              label="销售分组"
              width="150px"
            ></el-table-column>
          </el-table>
        </div>
        <div class="outTabOne">
          <el-table :data="tableDataTwo" style="width: 100%">
            <el-table-column
              prop="type"
              label="设备分类"
              width="150px"
            ></el-table-column>
            <el-table-column
              prop="name"
              label="设备名称"
              width="160px"
            ></el-table-column>
            <el-table-column
              prop="version"
              label="设备型号"
              width="140px"
            ></el-table-column>
            <el-table-column
              prop="imei"
              label="IMEI"
              width="160px"
            ></el-table-column>
            <el-table-column
              prop="status"
              label="出库判断"
              width="160px"
            ></el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="unSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
export default {
  data() {
    return {
      optionsLast: [
        {
          code: "1",
          name: "编号",
        },
        {
          code: "2",
          name: "IMEI",
        },
        {
          code: "3",
          name: "操作员工",
        },
      ],
      changehisNo: "",
      changeimei: "",
      changecreateId: "",
      outOpStatus: [],
      outOpWay: [],
      outOpType: [],
      optionsCreate: [],
      changeNum: "",
      isShow: true,
      dialogVisibleS: false,
      tableData: [],
      SerachInput: {},
      overallForm: {
        time: [],
      },
      opStatus: [],
      clientHeight: document.body.clientHeight,
      //分页
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      rowId: 0,
      tableDataOne: [],
      tableDataTwo: [],
      unitPriceShortMsgOne: "",
      unitPriceWebCallOne: "",
    };
  },
  created() {
    this.getList();
    this.outStatus();
    this.outboundWay();
    this.outboundType();
    this.houseCreate();
    apiShout.getGroups().then((res) => {
      if (res.data.code === 200) {
        this.opStatus = res.data.result;
      }
    });
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    changeSel(aa) {
      this.SerachInput.value = "";
      this.SerachInput.createId = "";
      this.changeNum = aa;
      if (aa != 3) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    configuration(index, row) {
      console.log("输出：", index, row);
      this.tableDataOne.push(row);
      console.log("输出上边的表格：", this.tableDataOne);
      apiShout.outHis({ id: row.id }).then((res) => {
        console.log("列表：", res.data);
        if (res.data.code == 200) {
          this.dialogVisibleS = true;
          this.tableDataTwo = res.data.result;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleCloseS() {
      this.dialogVisibleS = false;
      this.tableDataOne = [];
    },
    unSubmit() {
      this.dialogVisibleS = false;
      this.tableDataOne = [];
    },
    imaClick() {
      // this.getList();
      if (this.changeNum == 1) {
        this.changehisNo = this.SerachInput.value;
        this.changeimei = "";
        this.changecreateId = "";
      } else if (this.changeNum == 2) {
        this.changeimei = this.SerachInput.value;
        this.changehisNo = "";
        this.changecreateId = "";
      } else {
        this.changecreateId = this.SerachInput.createId;
        this.changeimei = "";
        this.changehisNo = "";
      }
      apiShout
        .outList({
          pageSize: this.pageSize,
          pageCount: 1,
          startTime:
            this.overallForm.time == null ? "" : this.overallForm.time[0],
          endTime:
            this.overallForm.time == null ? "" : this.overallForm.time[1],
          hisNo: this.changehisNo,
          imei: this.changeimei,
          createId: this.changecreateId,
          groupId: this.SerachInput.groupId,
          outWay: this.SerachInput.outWay,
          status: this.SerachInput.status,
          type: this.SerachInput.type,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.pageCount = res.data.result.page.pageCount;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    getList() {
      apiShout
        .outList({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          startTime:
            this.overallForm.time == null ? "" : this.overallForm.time[0],
          endTime:
            this.overallForm.time == null ? "" : this.overallForm.time[1],
          hisNo: this.changehisNo,
          imei: this.changeimei,
          createId: this.changecreateId,
          groupId: this.SerachInput.groupId,
          outWay: this.SerachInput.outWay,
          status: this.SerachInput.status,
          type: this.SerachInput.type,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.pageCount = res.data.result.page.pageCount;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //导出
    exportList() {
      let that = this;
      that
        .$http({
          url: "/too/center/crdcompany/exportList",
          params: {
            rechargeStart:
              this.overallForm.time == null ? "" : this.overallForm.time[0],
            rechargeEnd:
              this.overallForm.time == null ? "" : this.overallForm.time[1],
            balanceStart: this.SerachInput.balanceStart,
            balanceEnd: this.SerachInput.balanceEnd,
            number: this.SerachInput.number,
            groupId: this.SerachInput.groupId,
          },
          method: "post",
          responseType: "blob",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          // console.log(res,"查询")
          console.log("导出数据：", res.data);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
            crossOrigin: "Anonymous",
          });
          const a = document.createElement("a");
          let date = new Date();
          var year = date.getFullYear();
          var month =
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1;
          var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var minutes =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          var seconds =
            date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds();
          // 拼接
          let ds =
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          document.body.appendChild(a);
          a.style.display = "none";
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          // 指定下载的文件名
          a.download = "账户查询" + ds + ".xls";
          a.click();
          document.body.removeChild(a);
          // 移除blob对象的url
          window.URL.revokeObjectURL(url);
        });
    },
    //分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.getList();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.getList();
    },
    outStatus() {
      api.getSelectProduct({ dictCode: "equipmentstatus" }).then((res) => {
        //console.log("下拉框：", res.data);
        if (res.data.code == 200) {
          this.outOpStatus = res.data.result;
        }
      });
    },
    outboundWay() {
      api.getSelectProduct({ dictCode: "outboundway" }).then((res) => {
        //console.log("下拉框：", res.data);
        if (res.data.code == 200) {
          this.outOpWay = res.data.result;
        }
      });
    },
    outboundType() {
      api.getSelectProduct({ dictCode: "outboundtype" }).then((res) => {
        //console.log("下拉框：", res.data);
        if (res.data.code == 200) {
          this.outOpType = res.data.result;
        }
      });
    },
    houseCreate() {
      apiShout.houseMan().then((res) => {
        //console.log("下拉框：", res.data);
        if (res.data.code == 200) {
          this.optionsCreate = res.data.result;
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
.bang {
  font-size: 18px;
  // font-weight: 700;
  margin-bottom: 20px;
}
.inforOne {
  display: flex;
  margin-bottom: 20px;
}
.inforInput {
  width: 582px;
}
.inforDian {
  font-size: 18px;
  // font-weight: 700;
  width: 90px;
  // margin-top: 5px;
}
.police {
  margin-left: 10px;
}
.senOne {
  margin-right: 25px;
}
.xian1 {
  width: 103%;
  height: 2px;
  border-bottom: 1px solid #f3f3f3;
  margin-top: 30px;
  margin-bottom: 20px;
}
.baocun {
  background: hsl(3deg 31% 57%);
  border: none;
  padding: 8px 25px;
  font-size: 21px;
  color: #fff;
  border-radius: 30px;
}
.textMessage {
  /deep/ .el-input {
    width: 65%;
  }
  // /deep/ .el-select {
  //   width: 33%;
  // }
}
.creditOne {
  /deep/ .el-input {
    width: 33%;
    margin-right: 10px;
  }
}
.amount {
  margin-left: 20px;
}
.timeRight {
  margin-right: 15px;
}
.timeRightOne {
  margin-right: 23px;
}
.timeRightOne1 {
  margin-right: 62px;
}
.zhi {
  margin: auto 10px;
}
.qian {
  margin-right: 5px;
}
/deep/ .el-dialog__body {
  padding: 0;
  margin-left: 37px;
  margin-top: 24px;
}
/deep/ .el-input.is-disabled .el-input__inner {
  font-size: 16px;
}
/deep/.el-date-editor .el-range-separator {
  height: auto;
}
/deep/.el-input__icon {
  height: auto;
}
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
}
/deep/.el-input__icon {
  line-height: 33px;
}
/deep/.el-table th.el-table__cell > .cell {
  font-size: 14px;
  color: #909399;
}
/deep/.el-table td.el-table__cell div {
  color: #606266;
  font-size: 14px;
}
/deep/.el-table .el-table__cell {
  text-align: center;
}
/deep/.el-pager {
  margin-top: 5px;
}
.program {
  position: absolute;
  height: 100%;
  width: 100%;
  // 列表
  .programlist {
    margin-left: 25px;
    margin-right: 40px;
    // 上
    .protop {
      position: relative;
      margin-top: 25px;
      height: auto;
      margin-right: 40px;
      display: flex;
      align-items: center;
      span {
        margin-right: 10px;
        color: #909399;
        font-size: 14px;
        font-weight: bold;
      }
      // input框
      .el-input__inner {
        border-radius: 0;
        border: 1px solid #e1e1e1;
      }
      // 知识分类
      .knowledge {
        position: absolute;
        top: 3px;
        .el-input {
          width: 190px;
          margin-right: 0px;
        }
      }
      // 播放次数
      .play {
        position: absolute;
        left: 300px;
        top: 3px;
        .el-input {
          width: 134px;
        }
      }
      // 关键词
      .antistop {
        position: absolute;
        left: 700px;
        top: 3px;
        display: flex;
        align-items: center;
        .spanguan {
        }
        .el-input {
          width: 230px;
        }
        .buttons {
          margin-left: 14px;
        }
      }
    }
    // table
    .tables {
      margin-top: 10px;
      .el-table::before {
        height: 0;
      }
      .el-table {
        td,
        th.is-leaf {
          border: none !important;
        }
        tr th {
          font-size: 19px;
          color: #a8a8a8;
          text-align: center;
        }
        tr td {
          text-align: center;
          font-size: 17px;
          color: #a8a8a8;
        }
      }
      .buts {
        width: 36px;
        height: 27px;
      }
      .bu {
        margin-right: 20px;
        margin-left: 20px;
      }
    }
    /deep/ .el-table .el-table__cell {
      padding: 12px 0;
      min-width: 0;
      box-sizing: border-box;
      text-overflow: ellipsis;
      vertical-align: middle;
      position: relative;
      text-align: center;
    }
    /deep/ .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border-bottom: none;
    }
    /deep/ th.el-table__cell.is-leaf {
      border-bottom: none;
    }
    /deep/ .el-table td,
    .el-table th.is-leaf {
      border-bottom: none;
    }
    /deep/ .el-table td,
    .el-table th.is-leaf {
      border-bottom: none !important;
    }
    // 总结
    .total {
      display: flex;
      justify-content: space-between;
      margin-right: 0px;
      margin-top: 30px;
      margin-left: 34px;
      p {
        font-size: 15px;
        display: inline-block;
        margin-right: 36px;
        color: #606266;
      }
      .spanto {
        color: #92aca7;
        margin-left: 17px;
        margin-right: 12px;
      }
    }
  }
}
/deep/.el-pager li {
  padding: 0 4px;
  font-size: 13px;
  min-width: 35.5px;
  height: 28px;
  line-height: 15px;
  box-sizing: border-box;
  text-align: center;
}
.bottom {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  margin-top: 10px;
}
.bottom-right {
  float: right;
  margin-right: 20px;
}
.bottom-left {
  position: relative;
  //   display: flex;
}
// 按钮
.buttons {
  display: flex;
  justify-content: flex-end;
  // margin-right: 110px;
  .el-button {
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}
/deep/.el-table td,
.el-table th {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
/deep/.el-form-item__label {
  color: #909399;
}
.outTan {
  /deep/ .el-dialog__body {
    padding: 0;
    margin: 20px 40px 20px 40px !important;
  }
}
.outTabOne {
  max-height: 400px;
  overflow-y: scroll;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  margin: auto 30px;
}
//表格不滑动
/deep/.el-table {
  display: flex;
  flex-direction: column;
}
/deep/.el-table__header-wrapper {
  // overflow: visible !important; // 表头此时不会随着body左右滚动了
  height: 50px !important; // 这个方案更推荐！！！
}
/deep/ .el-input__inner {
  height: 35px !important;
  line-height: 35px !important;
  border-radius: 0px !important;
}
</style>